import { Anchor, ArrowIcon } from "./styles";
import AppLink, { AppLinkProps } from "@/components/AppLink";

export type InternalLinkProps = AppLinkProps & {
  title: string;
};

const InternalLink: React.FC<InternalLinkProps> = (props) => {
  const { title, page, params } = props;

  return (
    <AppLink page={page} params={params}>
      <Anchor>
        <span>{title}</span>
        <ArrowIcon />
      </Anchor>
    </AppLink>
  );
};

export default InternalLink;
